/* eslint-disable @typescript-eslint/no-unsafe-call */
import { httpService } from 'core-utilities';
import apiConstants from '../constants/apiEndpoint';
import {
  TChatMetadataResponse,
  TGetChatSettings,
  TGetChatUserSettingsResponse
} from '../types/profileHeaderTypes';

const getChatSettings = async (): Promise<TGetChatSettings> => {
  const { data: chatMetadataResponse } = await httpService.get<TChatMetadataResponse>(
    apiConstants.getChatMetadata()
  );
  if (!chatMetadataResponse.isWebChatSettingsMigrationEnabled) {
    return {
      chatEnabled: chatMetadataResponse.isChatEnabled
    };
  }

  const { data: chatUserSettingsResponse } = await httpService.get<TGetChatUserSettingsResponse>(
    apiConstants.getUserSettings(),
    {
      requestedUserSettings: 'whoCanChatWithMeInApp'
    }
  );
  const settingsValue = chatUserSettingsResponse.whoCanChatWithMeInApp?.currentValue;
  return {
    chatEnabled: settingsValue === 'Friends' || settingsValue === 'FriendsAndFriendsOfFriends'
  };
};

export default {
  getChatSettings
};
